import React from 'react'
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {Row, Col, Container} from 'reactstrap'
import {media} from "utils/Media"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import HeadingSpecial from "../../shared/HeadingSpecial";
import Paginator from "../../shared/Paginator";

const ScreenToStage = styled.div`
    position: relative;
    text-align: center;

    .narrow, p {
        max-width: 740px;
        margin: 0 auto;
    }

    h5 {
        margin-top: 1rem;
        padding: 0 15px;

        @media ${media.sm} {
            margin-top: 2rem;
            padding: 0;
        }
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                people1Md: file(relativePath: { eq: "ScreenToStagePage/adapt-screenstage-pic1-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                people1: file(relativePath: { eq: "ScreenToStagePage/adapt-screentostage-pic1-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 740, quality: 100)
                    }
                }
                people2Md: file(relativePath: { eq: "ScreenToStagePage/adapt-screenstage-pic2-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                people2: file(relativePath: { eq: "ScreenToStagePage/adapt-screentostage-pic2-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 740, quality: 100)
                    }
                }
                people3Md: file(relativePath: { eq: "ScreenToStagePage/adapt-screenstage-pic3-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                people3: file(relativePath: { eq: "ScreenToStagePage/adapt-screentostage-pic3-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 740, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <ScreenToStage>
                <Container fluid={true} className="no-padding-xs">
                    <HeadingSpecial lines={false} headingTag="h1"
                                    className="py-4 py-md-5 font--bigger"
                                    title="Screen to Stage"/>

                    <p className="font--regular mb-5 mx-auto narrow font-weight-bold">Teachers, take your experience further. <a
                        href="/pdf/FROZEN_LessonPlan_FromStageToScreen.pdf" target="_blank"
                        className="link no-interstitial-check">Download&nbsp;this&nbsp;lesson&nbsp;plan</a> exploring
                        character and point of view. </p>
                    <Row className="mb-4 mb-xl-5">
                        <Col xs={12} xl={6} className="my-auto order-2 order-xl-1">
                            <div className="narrow">
                                <p className="font--regular mb-4">
                                    Every musical comes together differently, but for FROZEN, Disney Theatrical
                                    Productions’
                                    President & Producer Thomas Schumacher first thought of bringing the story to the
                                    stage when he saw a rough cut of the film. Once the decision was made to move
                                    forward, a
                                    creative team was assembled, including the film’s writer, Jennifer Lee, and the
                                    film’s
                                    songwriters Kristen Anderson Lopez and Robert Lopez, in addition to a director
                                    (Michael
                                    Grandage), choreographer (Rob Ashford), designer (Christopher Oram).
                                </p>
                                <Paginator />
                            </div>
                        </Col>
                        <Col xs={12} xl={6} className="my-auto order-1 order-xl-1 pb-4 pb-xl-0">
                            <GatsbyImage placeholder="none" image={getImage(data.people2Md)} alt="" title=""
                                 className="img-fluid d-lg-none"/>
                            <GatsbyImage placeholder="none" image={getImage(data.people2)} alt="" title=""
                                 className="img-fluid d-none d-lg-block"/>
                            <h5 className="font--regular color--navy font-weight-bold">
                                Jennifer Lee, Thomas Schumacher, Kristen Anderson Lopez, Michael Grandage, Robert Lopez
                            </h5>
                        </Col>
                    </Row>

                    <Row className="mb-4 mb-xl-5">
                        <Col xs={12} xl={6} className="my-auto order-2 order-xl-2">
                            <div className="narrow">
                                <p className="font--regular mb-4">The first step in bringing any musical to the stage
                                    is a table read. During the table read, the creative team assembles a group of
                                    actors to read the show out loud. From there, revisions to the script and score are
                                    made in preparation for a ‘lab’, or ‘research and development’ workshop.</p>

                                <p className="font--regular mb-4">During a lab, the team has the opportunity to get the
                                    show up on its feet. The actors and creative team work together for several days and
                                    ultimately share a presentation of their work with the team of people working on the
                                    show, including designers, producers, marketing representatives and more.</p>
                                <Paginator />
                            </div>

                        </Col>
                        <Col xs={12} xl={6} className="my-auto order-1 order-xl-1 pb-4 pb-xl-0">
                            <GatsbyImage placeholder="none" image={getImage(data.people1Md)} alt="" title=""
                                 className="img-fluid d-lg-none"/>
                            <GatsbyImage placeholder="none" image={getImage(data.people1)} alt="" title=""
                                 className="img-fluid d-none d-lg-block"/>
                            <h5 className="font--regular color--navy font-weight-bold">
                                Kristen Anderson Lopez, Robert Lopez, Christopher Oram, Broadway Lighting Designer
                                Natasha Katz
                            </h5>
                        </Col>
                    </Row>

                    <Row className="mb-4 mb-xl-5">
                        <Col xs={12} xl={6} className="my-auto order-1 order-xl-2 pb-4 pb-xl-0">
                            <GatsbyImage placeholder="none" image={getImage(data.people3Md)} alt="" title=""
                                 className="img-fluid d-lg-none"/>
                            <GatsbyImage placeholder="none" image={getImage(data.people3)} alt="" title=""
                                 className="img-fluid d-none d-lg-block"/>
                            <h5 className="font--regular color--navy font-weight-bold">
                                Jennifer Lee, Kristen Anderson Lopez, Robert Lopez
                            </h5>
                        </Col>
                        <Col xs={12} xl={6} className="my-auto order-2 order-xl-1 pb-4 pb-xl-0">
                            <div className="narrow">
                                <p className="font--regular mb-4">The FROZEN team also decided to do extensive
                                    production research and development, including demonstrations of many of the
                                    technical elements being considered for the show. The team experimented with Elsa’s
                                    magic, creating a snow storm on stage, and how to freeze Arendelle.</p>

                                <p className="font--regular mb-4">The creative team spent two years in this development
                                    phase before assembling a professional cast of actors to workshop FROZEN and imagine
                                    what it might look like on stage.</p>
                                <Paginator />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </ScreenToStage>
        )}
    />
)

export default Query;
